// provider
import { QueryParamProvider } from "use-query-params";
import { Provider } from "react-redux";
import { NotificationProvider } from "src/providers/NotificationProvider";
import { AuthProvider } from "src/providers/AuthProvider";
// config
import { Store } from "src/redux/store";
import { Router } from "src/routes/router";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

function App() {
  return (
    <Provider store={Store}>
      <AuthProvider>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <NotificationProvider>
            <Router />
          </NotificationProvider>
        </QueryParamProvider>
      </AuthProvider>
    </Provider>
  );
}

export default App;
