// lib
import { createApi } from "@reduxjs/toolkit/query/react";
// utils
import { baseQuery, convertTag } from "src/utils/baseQuery";
// configs
import { ENDPOINTS, METHODS } from "src/constants/API";
import { apiHelper } from "src/utils/apiHelper";

const TAGS = {
  list: "tag-user-list",
};

export const UserApi = createApi({
  reducerPath: "user-api",
  baseQuery: baseQuery(true),
  tagTypes: Object.values(TAGS).map((t) => t),
  endpoints: (builder) => ({
    // USER LIST
    getUserList: builder.query({
      providesTags: [convertTag(TAGS.list)],
      query: (body) => apiHelper(ENDPOINTS.user.list, body),
    }),
    updateUser: builder.mutation({
      invalidatesTags: [convertTag(TAGS.list)],
      query: (body) => ({
        url: ENDPOINTS.user.update,
        method: METHODS.post,
        body,
      }),
    }),
    addCharge: builder.mutation({
      invalidatesTags: [convertTag(TAGS.list)],
      query: (body) => ({
        url: ENDPOINTS.user.charge,
        method: METHODS.post,
        body,
      }),
    }),
  }),
});

export const {
  useGetUserListQuery,
  useUpdateUserMutation,
  useAddChargeMutation,
} = UserApi;
