import React from "react";
import ReactDOM from "react-dom/client";
// provider
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "src/providers/HelmetProvider";
// config
import reportWebVitals from "./reportWebVitals";
// components
import App from "./App";
// styles
import "./theme/globals.css";
import "react-day-picker/style.css";

const rootElement = document.getElementById("root") as HTMLElement;
const root = ReactDOM.createRoot(rootElement);
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>,
);

reportWebVitals();
