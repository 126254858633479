// import { jwtDecode,  } from "jwt-decode";
// import { enqueueSnackbar } from "notistack";
//

// ----------------------------------------------------------------------

const isValidToken = (accessToken: string) => {
  return accessToken;

  // ----------------------------------------------------------------------

  // const decoded = jwtDecode(accessToken);
  // const currentTime = Date.now() / 1000;
  //
  // if ((decoded?.exp as any) < currentTime) {
  //   enqueueSnackbar("JWT token is expired", { variant: "warning" });
  // }
  //
  // return (decoded?.exp as any) > currentTime;
};

// const handleTokenExpired = (exp: number) => {
//   let expiredTimer;
//
//   window.clearTimeout(expiredTimer);
//   const currentTime = Date.now();
//   const timeLeft = exp * 1000 - currentTime;
//   expiredTimer = window.setTimeout(() => {
//     console.log("expired");
//     // You can do what ever you want here, like show a notification
//   }, timeLeft);
// };

// ----------------------------------------------------------------------

const getSession = () => {
  if (typeof window !== "undefined") {
    const token = localStorage.getItem("token");

    // if (!token) throw new Error("Token is undefined");

    return token || "";
  }
  return "";
};

const setSession = (token: string | null) => {
  if (token) {
    localStorage.setItem("token", token);
    // axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    // axios.defaults.headers.common.lt = token;

    // This function below will handle when token is expired
    // const { exp } = jwtDecode(token);
    // handleTokenExpired(exp as any);
  } else {
    localStorage.removeItem("token");
  }
};

export { isValidToken, setSession, getSession };
