import { createSlice } from "@reduxjs/toolkit/react";

interface IAuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: any;
}

const initialState: IAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: {},
};

const AuthReducer = createSlice({
  name: "auth-slice",
  initialState,
  reducers: {
    login: (state, payload) => {
      state.isAuthenticated = true;
      state.isInitialized = true;
      state.user = payload.payload.user;
    },
    initialize: (state, payload) => {},
  },
});

export const { login, initialize } = AuthReducer.actions;

export default AuthReducer.reducer;
