// router
import { Navigate, useRoutes } from "react-router-dom";
import { dynamicRoute, paths } from "./paths";
// components
import {
  AuthLogin,
  ProductCreate,
  ProductMint,
  ProductNFT,
  ProductsDetail,
  ProductsDetailUsers,
  ProductsList,
  ProductsUsers,
  ProductUpdate,
  ProductUploadPDF,
  ProjectDetails,
  ProjectList,
  TransactionsDetailBurn,
  TransactionsDetailCharge,
  TransactionsList,
  UsersCharge,
  UsersDetail,
  UsersList,
} from "./elements";
// layouts
import { MainLayout } from "src/components/layouts/MainLayout";
import { AuthLayout } from "src/components/layouts/AuthLayout";
import { AuthGuard } from "src/containers/guards/auth-guard";

export const Router = () => {
  return useRoutes([
    {
      path: paths.root,
      element: <Navigate to={paths.root} replace />,
    },
    {
      path: paths.auth.root,
      element: <AuthLayout />,
      children: [
        { element: <Navigate to={paths.auth.login} />, index: true },
        {
          path: paths.auth.login,
          element: <AuthLogin />,
        },
      ],
    },
    {
      path: paths.root,
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Navigate to={paths.users.list} replace />,
          index: true,
        },
        {
          path: paths.users.root,
          children: [
            {
              element: <Navigate to={paths.users.list} replace />,
              index: true,
            },
            {
              path: paths.users.list,
              element: <UsersList />,
            },
            {
              path: dynamicRoute(paths.users.detail, "userID"),
              element: <UsersDetail />,
            },
            {
              path: dynamicRoute(paths.users.charge, "userID"),
              element: <UsersCharge />,
            },
          ],
        },
        {
          path: paths.products.root,
          children: [
            {
              element: <Navigate to={paths.products.list} replace />,
              index: true,
            },
            {
              path: paths.products.list,
              element: <ProductsList />,
            },
            {
              path: paths.products.users,
              element: <ProductsUsers />,
            },
            {
              path: dynamicRoute(paths.products.detail.root, "productID"),
              element: <ProductsDetail />,
            },
            {
              path: paths.products.detail.users,
              element: <ProductsDetailUsers />,
            },
            {
              path: paths.products.create,
              element: <ProductCreate />,
            },
            {
              path: dynamicRoute(paths.products.mint, "productID"),
              element: <ProductMint />,
            },
            {
              path: dynamicRoute(paths.products.nft, "productID"),
              element: <ProductNFT />,
            },
            {
              path: dynamicRoute(paths.products.update, "productID"),
              element: <ProductUpdate />,
            },
            {
              path: dynamicRoute(paths.products["upload-pdf"], "productID"),
              element: <ProductUploadPDF />,
            },
          ],
        },
        {
          path: paths.projects.root,
          children: [
            {
              element: <Navigate to={paths.projects.list} />,
              index: true,
            },
            {
              path: paths.projects.list,
              element: <ProjectList />,
            },
            {
              path: dynamicRoute(paths.projects.details, "projectsID"),
              element: <ProjectDetails />,
            },
          ],
        },
        {
          path: paths.transactions.root,
          children: [
            {
              element: <Navigate to={paths.transactions.list} />,
              index: true,
            },
            {
              path: paths.transactions.list,
              element: <TransactionsList />,
            },
            {
              path: dynamicRoute(
                paths.transactions.detail.charge,
                "transactionID",
              ),
              element: <TransactionsDetailCharge />,
            },
            {
              path: dynamicRoute(
                paths.transactions.detail.burn,
                "transactionID",
              ),
              element: <TransactionsDetailBurn />,
            },
          ],
        },
      ],
    },
    {
      path: "*",
      element: <h1>Not found</h1>,
      // element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <h1>Not found</h1> },
        // { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
};
