// hooks
import { useHelmet } from "src/hooks/useHelmet";

export const HeaderTitle = () => {
  const { title } = useHelmet();
  return (
    <div className={"w-fit"}>
      <h1 className={"text-3xl font-bold text-black"}>{title}</h1>
    </div>
  );
};
