// components
import { HeaderAccount, HeaderTitle } from "src/components/common/Header";
import { Container } from "src/components/common/Container";

export const Header = () => {
  return (
    <div className={"h-20 w-full border-b border-b-border bg-background"}>
      <Container>
        <div
          className={"flex h-full w-full flex-row items-center justify-start"}
        >
          <HeaderTitle />

          <div className={"flex-1"} />

          <HeaderAccount />
        </div>
      </Container>
    </div>
  );
};
