// lib
import { createContext, useState } from "react";
import { HelmetProvider as HelmetAsyncProvider } from "react-helmet-async";
// types
import type { FC, PropsWithChildren } from "react";

interface IHelmetState {
  title: string;
  onTitleChange: (t: string) => void;
}

export const HelmetContext = createContext<IHelmetState>({
  title: "",
  onTitleChange: () => {},
});

export const HelmetProvider: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const [title, setTitle] = useState("");

  function onTitleChange(title: string) {
    setTitle(title);
  }

  return (
    <HelmetContext.Provider value={{ title, onTitleChange }}>
      <HelmetAsyncProvider>{children}</HelmetAsyncProvider>
    </HelmetContext.Provider>
  );
};
