// config
import { NavbarConfig } from "src/constants/NavbarConfig";
// component
import { VerticalNavbarItem } from "src/components/common/Navbar/VerticalNavbar/VerticalNavbarItem";
// utils
import { cn } from "src/utils/utils";

export const VerticalNavbarSection = () => {
  return (
    <div className={cn("w-full cursor-pointer p-4")}>
      <div className={"flex w-full flex-col gap-2"}>
        {NavbarConfig.map((navItem) => (
          <VerticalNavbarItem key={navItem.path} item={navItem} />
        ))}
      </div>
    </div>
  );
};
