/* eslint-disable no-unused-vars */
import { createContext, useEffect, useReducer } from "react";
// utils
import { isValidToken, setSession } from "src/utils/jwt";
// types
import type { FC, PropsWithChildren } from "react";

// ----------------------------------------------------------------------
interface UserTypes {
  _id: string;
  email: string;
  roles: string[];
  status: number;
  country: string;
  lastName: string;
  name: string;
  userType: "private" | "business";
}

interface AuthContextValuesTypes {
  isAuthenticated: boolean;
  isInitialized: boolean;
  roles: UserTypes["roles"];
  user: UserTypes;
}

const initialState: AuthContextValuesTypes = {
  isAuthenticated: false,
  isInitialized: false,
  roles: [],
  user: {
    userType: "private",
    roles: [],
    _id: "",
    country: "",
    email: "",
    name: "",
    lastName: "",
    status: 1,
  },
};

const handlers = {
  INITIALIZE: (state: any, action: any) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state: any, action: any) => {
    const { user, _id } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      _id,
      roles: user.roles,
    };
  },
  LOGOUT: (state: any) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    wallet: null,
    product: null,
  }),
};

const reducer = (state: any, action: any) =>
  // @ts-ignore
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  user: {
    _id: "",
    userType: "",
    country: "",
    name: "",
    lastName: "",
    taxID: "",
    status: 1,
    roles: [],
  },
  login: (data: any) => Promise.resolve(),
  logout: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const isCSR = typeof window !== "undefined";
  const [state, dispatch] = useReducer(reducer, initialState);
  // const { replace, reload } = useRouter();

  const token = isCSR && window.localStorage.getItem("token");
  const user = isCSR && window.localStorage.getItem("user");

  useEffect(() => {
    const initialize = () => {
      if (token && isValidToken(token) && user) {
        setSession(token);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: true,
            isInitialized: true,
            user: JSON.parse(user),
          },
        });
      } else {
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  function login(data: any) {
    if (isCSR) {
      const { user, session } = data;

      setSession(session.token);
      window.localStorage.setItem("user", JSON.stringify(user));
      setTimeout(() => {
        window.location.reload();
      }, 200);
      dispatch({
        type: "LOGIN",
        payload: {
          user,
          roles: user,
        },
      });
    }
  }

  function logout() {
    if (isCSR) {
      setSession(null);
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("user");

      dispatch({ type: "LOGOUT" });
    }
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
