import { lazy, Suspense } from "react";

export const Loadable = (Component: any) => (props: any) => (
  <Suspense fallback={null}>
    <Component {...props} />
  </Suspense>
);

const Component = (path: any) => {
  return Loadable(lazy(() => path));
};

// AUTH
export const AuthLogin = Component(import("src/pages/auth/login"));

// USERS
export const UsersList = Component(import("src/pages/users/list"));
export const UsersDetail = Component(import("src/pages/users/detail"));
export const UsersCharge = Component(import("src/pages/users/charge"));

// PROJECTS
export const ProductsList = Component(import("src/pages/products/list"));
export const ProductsUsers = Component(import("src/pages/products/users"));
export const ProductsDetail = Component(import("src/pages/products/detail"));
export const ProductsDetailUsers = Component(
  import("src/pages/products/detail-users"),
);
export const ProductCreate = Component(import("src/pages/products/create"));
export const ProductUpdate = Component(import("src/pages/products/update"));
export const ProductMint = Component(import("src/pages/products/mint"));
export const ProductNFT = Component(import("src/pages/products/nft"));
export const ProductUploadPDF = Component(
  import("src/pages/products/upload-file"),
);

// PROJECTS
export const ProjectList = Component(import("src/pages/projects/list"));
export const ProjectDetails = Component(import("src/pages/projects/details"));

// TRANSACTIONS
export const TransactionsList = Component(
  import("src/pages/transactions/list"),
);
export const TransactionsDetailBurn = Component(
  import("src/pages/transactions/burn"),
);
export const TransactionsDetailCharge = Component(
  import("src/pages/transactions/charge"),
);
