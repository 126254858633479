import { Outlet } from "react-router-dom";

export const AuthLayout = () => {
  return (
    <div
      className={
        "grid h-screen w-full grid-cols-2 items-center justify-center overflow-hidden"
      }
    >
      <div className={"flex w-full items-center justify-center"}>
        <Outlet />
      </div>
      <div className={"flex h-screen w-full items-center justify-center"}>
        <img
          src={"/Image/Gk.jpg"}
          alt={"Greenkeeper"}
          className={"h-auto w-full max-w-[500px] rounded-xl"}
        />
      </div>
    </div>
  );
};
