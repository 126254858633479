import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function capitalizeFirstLetter(string: string) {
  return string?.charAt(0).toUpperCase() + string.slice(1);
}

export const elipsis = (text: string, limit: number) => {
  return text?.length > limit ? `${text.slice(0, limit - 1)}...` : `${text}`;
};
