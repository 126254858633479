// lib
import { createApi } from "@reduxjs/toolkit/query/react";
// utils
import { baseQuery } from "src/utils/baseQuery";
// configs
import { ENDPOINTS } from "src/constants/API";
import { apiHelper } from "src/utils/apiHelper";

const TAGS = {
  list: "tag-project-list",
};

export const ProjectApi = createApi({
  reducerPath: "project-api",
  baseQuery: baseQuery(true),
  tagTypes: Object.values(TAGS).map((t) => t),
  endpoints: (builder) => ({
    getProjectList: builder.query({
      query: (body) => apiHelper(ENDPOINTS.project.list, body),
    }),
    getProject: builder.query({
      query: (body) => apiHelper(ENDPOINTS.project.single, body),
    }),
  }),
});

export const { useGetProjectQuery, useGetProjectListQuery } = ProjectApi;
