// lib
import { Outlet } from "react-router-dom";
// components
import { VerticalNavbar } from "src/components/common/Navbar";
import { Header } from "src/components/common/Header";
import { Container } from "src/components/common/Container";
// type
import type { FC } from "react";

export const MainLayout: FC = () => {
  return (
    <div className={"flex h-full min-h-screen w-full flex-row items-stretch"}>
      <VerticalNavbar />
      <div className={"flex w-full flex-col"}>
        <Header />
        <Container className={"py-4"}>
          <Outlet />
        </Container>
      </div>
    </div>
  );
};
