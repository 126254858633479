// types
import type { INavbarSubItem } from "src/constants/NavbarConfig";
import type { FC } from "react";
// utils
import { cn } from "src/utils/utils";
import { getActive } from "src/utils/isActivePath";
// lib
import { Link, useLocation } from "react-router-dom";

interface IVerticalNavbarSubItemProps {
  subItem: INavbarSubItem;
  onClose: () => void;
}
export const VerticalNavbarSubItem: FC<IVerticalNavbarSubItemProps> = (
  props,
) => {
  const { subItem } = props;

  const { pathname } = useLocation();
  const isActive = getActive(subItem.path!, pathname);

  return (
    <div className={"group flex w-full flex-row items-center gap-2 p-2"}>
      <span
        className={cn(
          "size-1.5 rounded-full bg-accent-foreground group-hover:bg-secondary",
          {
            "bg-secondary": isActive,
          },
        )}
      ></span>
      <Link
        className={cn(
          "text-sm font-semibold text-accent-foreground group-hover:text-secondary",
          {
            "text-secondary": isActive,
          },
        )}
        to={subItem.path!}
      >
        {subItem.title}
      </Link>
    </div>
  );
};
