import { LoaderCircle } from "lucide-react";

export const LoadingScreen = () => {
  return (
    <div
      className={
        "flex h-screen w-screen items-center justify-center bg-background"
      }
    >
      <LoaderCircle className={"size-20 animate-spin text-primary"} />
    </div>
  );
};
