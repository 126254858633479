// types
import type { FC, HTMLAttributes, PropsWithChildren } from "react";
// utils
import { cn } from "src/utils/utils";

interface IContainerProps {
  className?: HTMLAttributes<HTMLDivElement>["className"];
}

export const Container: FC<PropsWithChildren<IContainerProps>> = (props) => {
  const { children, className } = props;

  return (
    <div
      className={cn(
        "mx-auto h-full w-full px-4",
        { "max-w-7xl": false },
        className,
      )}
    >
      {children}
    </div>
  );
};
