// config
import { paths, PathsTitle } from "src/routes/paths";
// icons
import { Mountain, UserRound } from "lucide-react";

export interface INavbarSubItem {
  title: string;
  path: string;
  parent: string;
}
export interface INavbarItem {
  id: string;
  icon?: any;
  title: string;
  path?: string;
  children?: INavbarSubItem[];
}
export type TNavbarConfig = INavbarItem[];

export const NavbarConfig: TNavbarConfig = [
  {
    icon: UserRound,
    title: PathsTitle.users.root,
    path: paths.users.list,
    id: paths.users.root,
    children: [
      {
        title: PathsTitle.users.list,
        path: paths.users.list,
        parent: paths.users.root,
      },
      {
        title: PathsTitle.transactions.root,
        path: paths.transactions.list,
        parent: paths.users.root,
      },
      {
        title: PathsTitle.projects.root,
        path: paths.projects.list,
        parent: paths.users.root,
      },
    ],
  },
  {
    id: paths.products.root,
    icon: Mountain,
    title: PathsTitle.products.users,
    path: paths.products.list,
    children: [
      {
        title: PathsTitle.products.root,
        path: paths.products.list,
        parent: paths.products.root,
      },
    ],
  },
];
