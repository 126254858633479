// lib
import { createElement, useState } from "react";
// types
import type { INavbarItem } from "src/constants/NavbarConfig";
import type { FC } from "react";
// utils
import { cn } from "src/utils/utils";
import { getActive } from "src/utils/isActivePath";
// icons
import { ChevronDown } from "lucide-react";
// components
import { VerticalNavbarSubItem } from "src/components/common/Navbar/VerticalNavbar/VerticalNavbarSubItem";
import { useLocation } from "react-router-dom";

interface IVerticalNavbarItemProps {
  item: INavbarItem;
}
export const VerticalNavbarItem: FC<IVerticalNavbarItemProps> = (props) => {
  const { item } = props;
  const hasChild = item?.children?.length! > 0;

  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const isActive = getActive(item.path!, pathname);

  function handleToggleIsOpen() {
    setIsOpen(!isOpen);
  }
  function handleCloseMenuItem() {
    setIsOpen(false);
  }

  return (
    <div className={cn("w-full")}>
      <div
        className={cn(
          "group flex h-full w-full flex-row items-center justify-start gap-2 rounded px-4 py-4 transition-all duration-200 hover:bg-secondary-light hover:transition-all",
          {
            "bg-secondary-light": isActive,
          },
        )}
        onClick={handleToggleIsOpen}
      >
        {item.icon &&
          createElement(item.icon, {
            className: cn("text-accent-foreground group-hover:text-secondary", {
              "text-secondary": isActive,
            }),
          })}

        <p
          className={cn(
            "text-sm font-bold text-accent-foreground group-hover:text-secondary",
            {
              "text-secondary": isActive,
            },
          )}
        >
          {item.title}
        </p>

        {hasChild && (
          <ChevronDown
            className={cn(
              "ml-auto size-5 rotate-0 transition-all group-hover:text-secondary",
              {
                "rotate-180 text-secondary": isActive,
              },
            )}
          />
        )}
      </div>
      <div
        className={cn("invisible flex h-0 w-full flex-col px-4", {
          "visible h-fit transition-all": (isOpen && hasChild) || isActive,
        })}
      >
        {item.children?.map((subItem) => (
          <VerticalNavbarSubItem
            key={subItem.title}
            subItem={subItem}
            onClose={handleCloseMenuItem}
          />
        ))}
      </div>
    </div>
  );
};
