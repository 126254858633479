// lib
import { createApi } from "@reduxjs/toolkit/query/react";
// utils
import { baseQuery, convertTag } from "src/utils/baseQuery";
// configs
import { ENDPOINTS, METHODS } from "src/constants/API";
import { apiHelper } from "src/utils/apiHelper";

const TAGS = {
  list: "tag-product-list",
  singleProduct: "tag-single-product",
};

export const ProductApi = createApi({
  reducerPath: "product-api",
  baseQuery: baseQuery(true),
  tagTypes: Object.values(TAGS).map((t) => t),
  endpoints: (builder) => ({
    // PRODUCTS LIST
    getProductsList: builder.query({
      providesTags: [convertTag(TAGS.list)],
      query: (body) => apiHelper(ENDPOINTS.product.list, body),
    }),
    productCreate: builder.mutation({
      invalidatesTags: [convertTag(TAGS.list), convertTag(TAGS.singleProduct)],
      query: (body) => ({
        url: ENDPOINTS.product.create,
        method: METHODS.post,
        body,
      }),
    }),
    productUpdate: builder.mutation({
      invalidatesTags: [convertTag(TAGS.list)],
      query: (body) => ({
        url: ENDPOINTS.product.update,
        method: METHODS.post,
        body,
      }),
    }),
    deleteProductPhoto: builder.mutation({
      invalidatesTags: [convertTag(TAGS.singleProduct)],
      query: (body) => ({
        url: ENDPOINTS.product.deleteMedia,
        method: METHODS.post,
        body,
      }),
    }),
    getJetton: builder.query({
      query: (body) => apiHelper(ENDPOINTS.product.getJetton, body),
    }),
    getProductDetails: builder.query({
      providesTags: [convertTag(TAGS.singleProduct)],
      query: (body) => apiHelper(ENDPOINTS.product.getProduct, body),
    }),
    removeProductFromSale: builder.mutation({
      query: (body) => ({
        url: ENDPOINTS.product.removeFromSale,
        method: METHODS.post,
        body,
      }),
    }),
  }),
});

export const {
  useGetProductsListQuery,
  useProductCreateMutation,
  useProductUpdateMutation,
  useDeleteProductPhotoMutation,
  useRemoveProductFromSaleMutation,
  useGetProductDetailsQuery,
} = ProductApi;
