import { ChevronDown, LogOut } from "lucide-react";
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
} from "src/components/ui/menubar";
import { useAuth } from "src/hooks/useAuth";

export const HeaderAccount = () => {
  const { user, logout } = useAuth();

  return (
    <div>
      <Menubar
        className={
          "mx-6 mr-16 flex h-10 w-10 items-center justify-center border-none"
        }
      >
        <MenubarMenu>
          <MenubarTrigger asChild>
            <div className={"flex flex-row items-center gap-1"}>
              <div className={"flex flex-col"}>
                <p className={"text-sm font-bold text-black"}>Hello!</p>
                <p className={"text-xs font-semibold text-accent-foreground"}>
                  {user?.roles?.[0]}
                </p>
              </div>
              <ChevronDown className={"size-3"} />
            </div>
          </MenubarTrigger>
          <MenubarContent align={"end"}>
            <MenubarItem
              onClick={() => {
                logout();
              }}
            >
              <LogOut className={"mr-2 size-4"} />
              Logout
            </MenubarItem>
          </MenubarContent>
        </MenubarMenu>
      </Menubar>
    </div>
  );
};
