// utils
import { cn } from "src/utils/utils";
// type
import type { FC } from "react";

interface ILogoProps {
  width?: string;
  height?: string;
}

export const Logo: FC<ILogoProps> = (props) => {
  const { width, height } = props;
  return (
    <div className={cn("flex w-full items-center justify-center p-4")}>
      <img
        src={"/Image/Logo.svg"}
        alt={"LOGO"}
        className={cn(
          height ? `h-[${height}]` : "h-auto",
          width ? `w-[${width}]` : "w-full",
        )}
      />
    </div>
  );
};
