import {
  combineReducers,
  configureStore,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
// api slices
import { UserApi } from "src/redux/api-slices/user.api";
import { AuthApi } from "src/redux/api-slices/auth.api";
import { ProductApi } from "src/redux/api-slices/product.api";
import { ProjectApi } from "src/redux/api-slices/project.api";
import { TransactionApi } from "src/redux/api-slices/transaction.api";
// reducer slices
import AuthReducer from "src/redux/actions-slices/auth.reducer";
import { GlobalErrorHandler } from "src/utils/apiHelper";

export const rtkQueryErrorLogger =
  (api: any) => (next: any) => (action: any) => {
    if (isRejectedWithValue(action)) {
      GlobalErrorHandler(action.payload);
    }
    return next(action);
  };

const rootReducer = combineReducers({
  [AuthApi.reducerPath]: AuthApi.reducer,
  [UserApi.reducerPath]: UserApi.reducer,
  [TransactionApi.reducerPath]: TransactionApi.reducer,
  [ProductApi.reducerPath]: ProductApi.reducer,
  [ProjectApi.reducerPath]: ProjectApi.reducer,
  auth: AuthReducer,
});

export const Store = configureStore({
  reducer: rootReducer,
  // @ts-ignore
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(AuthApi.middleware)
      .concat(UserApi.middleware)
      .concat(TransactionApi.middleware)
      .concat(ProductApi.middleware)
      .concat(ProjectApi.middleware)
      .concat(rtkQueryErrorLogger),
});

export type RootState = ReturnType<typeof Store.getState>;
export type AppDispatch = typeof Store.dispatch;
