const dynamicRoute = (path: string, param: string) => `${path}/:${param}`;
const paths = {
  root: "/",
  auth: {
    root: "/auth",
    login: "/auth/login",
    code: "/auth/code",
  },
  users: {
    root: "/users",
    list: "/users/list",
    detail: "/users/detail",
    charge: "/users/charge",
  },
  transactions: {
    root: "/transactions",
    list: "/transactions/list",
    detail: {
      root: "/transactions",
      charge: "/transactions/detail/charge",
      burn: "/transactions/detail/burn",
    },
  },
  products: {
    root: "/products",
    list: "/products/list",
    users: "/products/users",
    detail: {
      root: "/products/detail",
      users: "/products/detail/users",
    },
    create: "/products/create",
    update: "/products/update",
    "upload-pdf": "/products/upload-pdf",
    mint: "/products/mint",
    nft: "/products/nft",
  },
  projects: {
    root: "/projects",
    list: "/projects/list",
    details: "/projects/details",
  },
};
const PathsTitle = {
  auth: {
    root: "Auth",
    login: "Auth login",
  },
  users: {
    root: "User",
    list: "User list",
    detail: "User detail",
    charge: "Charge GFT",
  },
  transactions: {
    root: "Transactions",
    list: "Transactions list",
    detail: {
      charge: "Charge details",
      burn: "Compensation details",
    },
  },
  products: {
    root: "Projects",
    list: "Greenkeeper Projects",
    users: "Greenkeeper Projects",
    detail: {
      root: "Projects Detail ",
      users: "/products/detail/users",
    },
    create: "Create Projects",
    update: "Update Projects",
    mint: "Mint Token",
    nft: "Mint NFT",
    "upload-pdf": "Upload PDF Files",
  },
  projects: {
    root: "Projects",
    list: "Projects",
    details: "Project details",
  },
};

export { paths, PathsTitle, dynamicRoute };
