// lib
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// configs
import { BASE_URL } from "src/constants/API";
import { getSession } from "src/utils/jwt";

export const baseQuery = (withHeader: boolean = false) => {
  return fetchBaseQuery({
    baseUrl: BASE_URL,
    ...(withHeader && {
      headers: {
        lt: getSession(),
        ln: "en",
      },
    }),
  });
};

export const convertTag = (tag: string) => ({ type: tag, id: tag });
