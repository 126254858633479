// lib
import { createApi } from "@reduxjs/toolkit/query/react";
// utils
import { baseQuery } from "src/utils/baseQuery";
// configs
import { ENDPOINTS } from "src/constants/API";
import { METHODS } from "src/constants/API";
// types
import type { ILoginRequest } from "src/types/auth.types";

const TAGS = {
  profile: "tag-profile",
};

export const AuthApi = createApi({
  reducerPath: "auth-api",
  baseQuery: baseQuery(false),
  tagTypes: Object.values(TAGS).map((t) => t),
  endpoints: (builder) => ({
    // AUTH
    login: builder.mutation({
      query: (body: ILoginRequest) => ({
        url: ENDPOINTS.auth.login,
        method: METHODS.post,
        body,
      }),
    }),
  }),
});

export const { useLoginMutation } = AuthApi;
