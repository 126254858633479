import { Logo } from "src/components/common/Logo";
import { VerticalNavbarSection } from "src/components/common/Navbar/VerticalNavbar/VerticalNavbarSection";

export const VerticalNavbar = () => {
  return (
    <div
      className={
        "relative h-full min-h-screen w-full min-w-80 max-w-80 border-r"
      }
    >
      <div className={"flex h-full w-full flex-col items-center py-4"}>
        <Logo width={"720px"} />
        <VerticalNavbarSection />
      </div>
    </div>
  );
};
