// lib
import { createApi } from "@reduxjs/toolkit/query/react";
// utils
import { baseQuery, convertTag } from "src/utils/baseQuery";
// configs
import { ENDPOINTS } from "src/constants/API";
import { apiHelper } from "src/utils/apiHelper";

const TAGS = {
  list: "tag-transaction-list",
};

export const TransactionApi = createApi({
  reducerPath: "transaction-api",
  baseQuery: baseQuery(true),
  tagTypes: Object.values(TAGS).map((t) => t),
  endpoints: (builder) => ({
    // USER LIST
    getTransactionList: builder.query({
      providesTags: [convertTag(TAGS.list)],
      query: (body) => apiHelper(ENDPOINTS.transaction.list, body),
    }),
    // updateUser: builder.mutation({
    //   invalidatesTags: [convertTag(TAGS.list)],
    //   query: (body) => ({
    //     url: ENDPOINTS.user.update,
    //     method: METHODS.post,
    //     body,
    //   }),
    // }),
  }),
});

export const { useGetTransactionListQuery } = TransactionApi;
