import { toast } from "sonner";

export const apiHelper = (url: string, filters: any) => {
  let filterParams = {};
  Object.keys(filters).forEach((key) => {
    filterParams = {
      ...filterParams,
      ...(filters[key] ? { [key]: filters[key] } : {}),
    };
  });
  const joinFilters = Object.entries(filterParams).join("&");
  const finalFilters = joinFilters.replaceAll(",", "=");

  return finalFilters !== "" ? `${url}?${finalFilters}` : url;
};

export const GlobalErrorHandler = (error: any) => {
  console.log("GLOBAL ERROR", error);
  if (
    error?.status === "FETCH_ERROR" &&
    error?.error === "TypeError: Failed to fetch"
  ) {
    toast.error("Something went wrong", {
      description: "Check your connection",
      position: "top-center",
    });
  }
};
